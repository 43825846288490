export interface IGoogleAds {
    id?: number;
    customer_id: number;
    action: string;
    status: string;
    description: string;
    created_at?: string;
    updated_at?: string;
}

export const SORT_OPTIONS = [
    { value: 'sort=-created_at', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
    { value: 'sort=created_at', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
]
