import React from 'react'
import { Link, useLocation, withRouter } from 'react-router-dom'
import { useIntl } from "react-intl"

const LogsHeader: React.FC = () => {
    const location = useLocation()
    const intl = useIntl()

    return (
        <div className='card'>
            <div className='card-body pb-0'>
                <div className='d-flex overflow-auto'>
                    <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/logs/ads` ? 'active' : '')
                                }
                                to={`/logs/ads`}
                            >
                                {intl.formatMessage({id: 'GOOGLE_ADS_LOGS'})}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/logs/requests` ? 'active' : '')
                                }
                                to={`/logs/requests`}
                            >
                                {intl.formatMessage({id: 'MICROSOFT_ADS_NETWORK'})}
                            </Link>
                        </li>
                        <li className='nav-item'>
                            <Link
                                className={
                                    `nav-link text-active-primary me-6 ` +
                                    (location.pathname === `/logs/errors` ? 'active' : '')
                                }
                                to={`/logs/errors`}
                            >
                                {intl.formatMessage({id: 'MICROSOFT_ADS_ERRORS'})}
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default withRouter(LogsHeader)
