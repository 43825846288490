import axios from 'axios'
import {IProject} from '../models/Project'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const PROJECT_API = `${API_URL}/projects`

export async function getProjectsList(
    page: number,
    perPage: number,
    filter: string,
    search?: string
) {
  const encodedSearch = search ? encodeURIComponent(search) : '';
  const response = search
      ? await axios.get(
          `${PROJECT_API}?search=${encodedSearch}&page=${page}&per-page=${perPage}&${filter}`
      )
      : await axios.get(`${PROJECT_API}?page=${page}&per-page=${perPage}&${filter}`);

  console.log('API response:', response.data);

  return response;
}

export async function getProjectById(projectId: string) {
  return await axios.get(`${PROJECT_API}/${projectId}`)
}

export async function deleteProject(projectId: string) {
  return await axios.delete(`${PROJECT_API}/${projectId}`)
}

export async function updateProject(updateProject: IProject) {
  return await axios
    .put(`${PROJECT_API}/${updateProject.id}`, updateProject)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}

export async function createProject(newProject: IProject) {
  return await axios
    .post(`${PROJECT_API}`, newProject)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
