import React, { useRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import EmailTemplateForm, { EmailTemplateFormRef } from './EmailTemplateForm'
import { updateEmailTemplate } from '../redux/EmailTemplateCRUD'
import { IEmailTemplate } from '../models/EmailTemplate'
import { AlertContext } from '../../../../_metronic/layout/core/AlertContext'
import {IEmailTemplateLang} from "../models/EmailTemplateLang"

interface EditEmailTemplateModalProps {
    show: boolean
    handleClose: () => void
    template: IEmailTemplate
    loadEmailTemplates: () => void
}

const EditEmailTemplateModal: React.FC<EditEmailTemplateModalProps> = ({
                                                                           show,
                                                                           handleClose,
                                                                           template,
                                                                           loadEmailTemplates,
                                                                       }) => {
    const intl = useIntl()
    const { showAlert } = React.useContext(AlertContext)
    const formRef = useRef<EmailTemplateFormRef>(null)

    const handleEditTemplate = async (code: string, translations: IEmailTemplateLang[]) => {
        const updatedTemplate = { id: template.id, code, translations }
        try {
            await updateEmailTemplate(template.id!, updatedTemplate)
            showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.EDIT.SUCCESS' })]], 'success', true, 'SUCCESS')
            handleClose()
            loadEmailTemplates()
        } catch (error: any) {
            showAlert([[intl.formatMessage({ id: error ?? 'EMAIL_TEMPLATE.EDIT.ERROR' })]], 'danger', true)
        }
    }

    const handleSave = () => {
        formRef.current?.submitForm()
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'EDIT_EMAIL_TEMPLATE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailTemplateForm
                    ref={formRef}
                    initialCode={template.code}
                    initialTranslations={template.translations}
                    onSubmit={handleEditTemplate}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant='primary' onClick={handleSave}>
                    {intl.formatMessage({ id: 'SAVE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default EditEmailTemplateModal
