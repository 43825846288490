import React, { useCallback, useEffect, useState, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../../setup'
import { actions, selectors } from '../../redux/googleCategories/GoogleCategoryRedux'
import { Button, Table } from 'react-bootstrap'
import Pagination from '../../../common/Pagination/Pagination'
import EditGoogleCategoryModal from './EditGoogleCategoryModal'
import { IGoogleCategory, GOOGLE_CATEGORY_LIST_FILTERS } from '../../models/GoogleCategory'
import { AlertContext } from '../../../../../_metronic/layout/core/AlertContext'
import PageItemsSelect from '../../../common/PageItemsSelect/PageItemsSelect'
import { useIntl } from "react-intl"
import { KTSVG } from "../../../../../_metronic/helpers"
import { DateUtil } from "../../../../../_metronic/assets/ts/_utils"

const GoogleCategories: React.FC = () => {
    const intl = useIntl()
    const [showEditModal, setShowEditModal] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState<IGoogleCategory | null>(null)
    const [searchQuery, setSearchQuery] = useState<string>('')
    const [filteredCategories, setFilteredCategories] = useState<IGoogleCategory[]>([])

    const dispatch = useDispatch()
    const { showAlert } = useContext(AlertContext)

    const categories = useSelector((state: RootState) => selectors.getGoogleCategories(state))
    const loading = useSelector((state: RootState) => state.googleCategories.loading)
    const error = useSelector((state: RootState) => state.googleCategories.error)

    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage)
    const pagedCategories = filteredCategories.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    const [listFilter, setListFilter] = useState<string>(GOOGLE_CATEGORY_LIST_FILTERS[0].value)

    const onFilterHandle = useCallback(
        (event) => {
            setListFilter(event.target.value)
            dispatch(actions.changeGoogleCategoryFilter(event.target.value))
            dispatch(actions.requestGoogleCategories())
        },
        [dispatch]
    )

    const loadCategories = useCallback(() => {
        dispatch(actions.requestGoogleCategories())
    }, [dispatch])

    useEffect(() => {
        loadCategories()
    }, [loadCategories])

    useEffect(() => {
        setFilteredCategories(
            categories.filter((category) => {
                const name = category.name.toLowerCase()
                const createdAt = DateUtil.formatDate(category.created_at).toLowerCase()

                const attributeNames = category.attributes
                    ?.map((attribute) => attribute.name.toLowerCase())
                    .join(' ') || ''

                const matchesSearch =
                    name.includes(searchQuery.toLowerCase()) ||
                    createdAt.includes(searchQuery.toLowerCase()) ||
                    attributeNames.includes(searchQuery.toLowerCase())

                return matchesSearch
            })
        )
    }, [searchQuery, categories])

    const handleOpenEditModal = (category: IGoogleCategory) => {
        if (category.id !== undefined) {
            setSelectedCategory(category)
            setShowEditModal(true)
        }
    }

    const handleSaveChanges = (industry_id: number, attribute_ids: number[]) => {
        dispatch(actions.updateGoogleCategory(industry_id, attribute_ids))
        setShowEditModal(false)
        showAlert([[intl.formatMessage({ id: 'EDIT.INDUSTRY.SUCCESS' })]], 'success', true, intl.formatMessage({ id: 'SUCCESS' }))
    }

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({id: 'INDUSTRY.LIST'})}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={listFilter}
                            handle={onFilterHandle}
                            options={GOOGLE_CATEGORY_LIST_FILTERS}
                        />
                        <input
                            type="text"
                            placeholder={intl.formatMessage({ id: 'SEARCH' })}
                            className="form-control form-control-sm w-auto"
                            style={{ minWidth: '150px', minHeight: '40px' }}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                    </div>
                </div>
            </div>

            {loading && <p>{intl.formatMessage({id: 'LOADING'})}...</p>}
            {error && <p>{intl.formatMessage({id: 'LOADING.DATA.ERROR'})}.</p>}

            {!loading && !error && pagedCategories.length > 0 ? (
                <div>
                    <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-8">
                        <thead>
                        <tr className="fw-bolder text-muted">
                            <th>ID</th>
                            <th>{intl.formatMessage({ id: 'SETTING.NAME' })}</th>
                            <th>{intl.formatMessage({ id: 'REQUIRED_ATTRIBUTES' })}</th>
                            <th>{intl.formatMessage({ id: 'PROJECT.CREATED_AT' })}</th>
                            <th>{intl.formatMessage({ id: 'ACTIONS' })}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {pagedCategories.map((category) => (
                            <tr key={category.id}>
                                <td>{category.id}</td>
                                <td>{category.name}</td>
                                <td>
                                    {category?.attributes?.map((attribute) => (
                                        <React.Fragment key={attribute.id}>
                                            {attribute.name}{' '}
                                            {attribute.global ? (
                                                <span className="text-lowercase">
                                                    ({intl.formatMessage({ id: 'GLOBAL' })})
                                                </span>
                                            ) : (
                                                ''
                                            )}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </td>
                                <td>{DateUtil.formatDate(category.created_at)}</td>
                                <td>
                                    <Button
                                        className="btn btn-icon btn-bg-light btn-active-color-warning btn-sm me-1"
                                        variant="light"
                                        onClick={() => handleOpenEditModal(category)}
                                    >
                                        <KTSVG
                                            path="/media/icons/duotone/Communication/Write.svg"
                                            className="svg-icon-3"
                                        />
                                    </Button>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                    {selectedCategory && selectedCategory.id !== undefined && (
                        <EditGoogleCategoryModal
                            show={showEditModal}
                            handleClose={() => setShowEditModal(false)}
                            categoryId={selectedCategory.id}
                            categoryName={selectedCategory.name}
                            categoryAttributes={selectedCategory.attributes || []}
                            onSave={handleSaveChanges}
                        />
                    )}
                </div>
            ) : (
                !loading && <p>{intl.formatMessage({id: 'INDUSTRY.NO_INDUSTRIES'})}.</p>
            )}

            <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    )
}

export default GoogleCategories
