import { IRequest } from "../../models/Request"
import { ActionWithPayload } from "../../../../../setup"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"
import { getRequests} from "./RequestCRUD"

export const REQUESTS_REQUEST = 'REQUESTS_REQUEST'
export const REQUESTS_REQUEST_SUCCESS = 'REQUESTS_REQUEST_SUCCESS'
export const REQUESTS_REQUEST_FAIL = 'REQUESTS_REQUEST_FAIL'

export interface IRequestState {
    requests: IRequest[]
    loading: boolean
    total: number
}


const initialState: IRequestState = {
    requests: [],
    loading: false,
    total: 0,
}


export const reducer = (
    state: IRequestState = initialState,
    action: ActionWithPayload<any>
): IRequestState => {
    switch (action.type) {
        case REQUESTS_REQUEST:
            return { ...state, loading: true }
        case REQUESTS_REQUEST_SUCCESS:
            return {
                ...state,
                requests: action.payload.requests || [],
                total: action.payload.total || 0,
                loading: false,
            }
        case REQUESTS_REQUEST_FAIL:
            return { ...state, requests: [], total: 0, loading: false }
        default:
            return state
    }
}

export const actions = {
    requestRequests: (params: { sortOrder: string; page: number; perPage: number }) => ({
        type: REQUESTS_REQUEST,
        payload: params,
    }),
    requestRequestsSuccess: (requests: IRequest[], total: number) => ({
        type: REQUESTS_REQUEST_SUCCESS,
        payload: { requests, total },
    }),
    requestRequestsFail: () => ({ type: REQUESTS_REQUEST_FAIL }),
}


function* fetchRequests(
    action: ActionWithPayload<{ sortOrder: string; page: number; perPage: number }>
): SagaIterator {
    try {
        if (!action.payload) {
            throw new Error('Missing payload')
        }

        const { sortOrder, page, perPage } = action.payload

        const response = yield call(() =>
            getRequests(`${sortOrder}&page=${page}&limit=${perPage}`)
        )

        const { data, meta } = response.data

        yield put(actions.requestRequestsSuccess(data, meta.total))
    } catch (error) {
        yield put(actions.requestRequestsFail())
    }
}

export function* saga(): SagaIterator {
    yield takeLatest(REQUESTS_REQUEST, fetchRequests)
}
