export interface IError {
    message: string
    stacktrace?: string | null
    additional_info?: string | null
    created_at: string
    updated_at: string
}

export const SORT_OPTIONS = [
    { value: 'sort=-created_at', label: 'CLIENTS.LIST.SORT.CREATE_DOWN' },
    { value: 'sort=created_at', label: 'CLIENTS.LIST.SORT.CREATE_UP' },
]