import {
  ActionWithDefinePayload,
  ActionWithPayload,
  RootState,
} from '../../../../setup/redux/RootReducer'
import {put, takeLatest} from 'redux-saga/effects'
import {getPaymentById, getUserPaymentsList, updatePayment} from './UserPaymentsCRUD'
import {IUserPayment} from '../models/UserPayment'
import {select} from '@redux-saga/core/effects'
import {ISummary} from "../models/Summary"

export const USER_PAYMENTS_REQUEST = 'USER_PAYMENTS_REQUEST'
export const USER_PAYMENTS_REQUEST_SUCCESS = 'USER_PAYMENTS_REQUEST_SUCCESS'
export const USER_PAYMENTS_REQUEST_FAIL = 'USER_PAYMENTS_REQUEST_FAIL'

export const GET_PAYMENT_TO_UPDATE = 'GET_PAYMENT_TO_UPDATE'
export const GET_PAYMENT_TO_UPDATE_REQUEST = 'GET_PAYMENT_TO_UPDATE_REQUEST'
export const GET_PAYMENT_TO_UPDATE_FAIL = 'GET_PAYMENT_TO_UPDATE_FAIL'

export const PUT_PAYMENT_UPDATE_REQUEST = 'PUT_PAYMENT_UPDATE_REQUEST'
export const PUT_PAYMENT_UPDATE_SUCCESS = 'PUT_PAYMENT_UPDATE_SUCCESS'
export const PUT_PAYMENT_UPDATE_FAIL = 'PUT_PAYMENT_UPDATE_FAIL'

export interface IUserPaymentState {
  getUserPayments?: IUserPayment[]
  getUserPaymentsLoading: boolean
  getUserPaymentsError: boolean
  currentPage?: number
  summary?: ISummary

  getPaymentLoading: boolean
  getPaymentError: boolean
  getPaymentToUpdateId?: number
  getPaymentToUpdateData?: IUserPayment

  paymentUpdateData?: IUserPayment
  paymentUpdateLoading: boolean
  paymentUpdateError: boolean
  paymentIsUpdate: boolean
  paymentUpdateErrorMessage?: string[][]
}

const initialUserPaymentState: IUserPaymentState = {
  getUserPayments: [],
  getUserPaymentsLoading: false,
  getUserPaymentsError: false,
  currentPage: 1,
  summary: undefined,

  getPaymentLoading: false,
  getPaymentError: false,
  getPaymentToUpdateId: undefined,
  getPaymentToUpdateData: undefined,

  paymentUpdateData: undefined,
  paymentUpdateLoading: false,
  paymentUpdateError: false,
  paymentIsUpdate: false,
  paymentUpdateErrorMessage: undefined,
}

export const reducer = (
  state: IUserPaymentState = initialUserPaymentState,
  action: ActionWithPayload<IUserPaymentState>
) => {
  switch (action.type) {
    case USER_PAYMENTS_REQUEST:
      return {
        ...state,
        getUserPaymentsLoading: true,
      }
    case USER_PAYMENTS_REQUEST_SUCCESS:
      const payments = action.payload?.getUserPayments
      return {
        ...state,
        getUserPayments: payments,
        summary: action.payload?.summary,
        getUserPaymentsLoading: false,
        getUserPaymentsError: false,
      }
    case USER_PAYMENTS_REQUEST_FAIL:
      return {
        ...state,
        error: true,
      }
    case GET_PAYMENT_TO_UPDATE:
      const paymentIdToUpdate = action.payload?.getPaymentToUpdateId
      return {
        ...state,
        getPaymentToUpdateId: paymentIdToUpdate,
        getPaymentToUpdateData: undefined,
        getPaymentLoading: true,
      }
    case GET_PAYMENT_TO_UPDATE_REQUEST:
      const paymentToUpdateData = action.payload?.getPaymentToUpdateData
      return {
        ...state,
        getPaymentToUpdateData: paymentToUpdateData,
        getPaymentLoading: false,
      }
    case GET_PAYMENT_TO_UPDATE_FAIL:
      return {
        ...state,
        getPaymentLoading: false,
        getPaymentError: true,
      }
    case PUT_PAYMENT_UPDATE_REQUEST:
      const paymentData = action.payload?.paymentUpdateData
      return {
        ...state,
        paymentUpdateLoading: true,
        paymentUpdateData: paymentData,
        paymentIsUpdate: false,
        paymentUpdateError: false,
      }
    case PUT_PAYMENT_UPDATE_SUCCESS:
      return {
        ...state,
        paymentUpdateLoading: false,
        paymentIsUpdate: true,
      }
    case PUT_PAYMENT_UPDATE_FAIL:
      const paymentUpdateErrorMessage = action.payload?.paymentUpdateErrorMessage
      return {
        ...state,
        paymentUpdateLoading: false,
        paymentUpdateError: true,
        paymentIsUpdate: false,
        paymentUpdateErrorMessage: paymentUpdateErrorMessage,
      }
    default:
      return state
  }
}

export const actions = {
  requestUserPayments: (subscriptionId: string, clientId: string) => ({
    type: USER_PAYMENTS_REQUEST,
    payload: {subscriptionId, clientId},
  }),
  requestUserPaymentsSuccess: (payments: IUserPayment[], summary: ISummary) => ({
    type: USER_PAYMENTS_REQUEST_SUCCESS,
    payload: {getUserPayments: payments, summary},
  }),
  requestUserPaymentsFail: () => ({
    type: USER_PAYMENTS_REQUEST_FAIL,
  }),

  changePaymentToUpdateId: (paymentId: string) => ({
    type: GET_PAYMENT_TO_UPDATE,
    payload: {getPaymentToUpdateId: paymentId},
  }),

  requestPaymentToUpdateData: (getPaymentToUpdateData: IUserPayment) => ({
    type: GET_PAYMENT_TO_UPDATE_REQUEST,
    payload: {getPaymentToUpdateData},
  }),

  requestPaymentToUpdateDataFail: () => ({
    type: GET_PAYMENT_TO_UPDATE_FAIL,
  }),

  updatePaymentData: (paymentData: IUserPayment) => ({
    type: PUT_PAYMENT_UPDATE_REQUEST,
    payload: {paymentUpdateData: paymentData},
  }),

  updatePaymentDataSuccess: () => ({
    type: PUT_PAYMENT_UPDATE_SUCCESS,
  }),

  requestPaymentToUpdateFail: (err: any) => ({
    type: PUT_PAYMENT_UPDATE_FAIL,
    payload: {paymentUpdateErrorMessage: err},
  }),
}

export const selectors = {
  getUserPayments: (state: RootState) => state.userPayments,
  getSummary: (state: RootState) => state.userPayments.summary,
  getPaymentToUpdateId: (state: RootState) => state.userPayments.getPaymentToUpdateId,
  getPaymentToUpdateData: (state: RootState) => state.userPayments.getPaymentToUpdateData,
  getUpdatePaymentData: (state: RootState) => {
    const {paymentUpdateLoading} = state.userPayments
    return {
      paymentUpdateLoading,
    }
  },
  getPaymentDataToUpdate: (state: RootState) => state.userPayments.paymentUpdateData,
}

function* listUpdate(subscriptionId: string, clientId: string) {
  const {data} = yield getUserPaymentsList(subscriptionId, clientId)
  return {data}
}

export function* saga() {
  yield takeLatest(
      USER_PAYMENTS_REQUEST,
      function* getUserPaymentsSaga(action: ActionWithDefinePayload<{subscriptionId: string, clientId: string}>) {
        try {
          const {data} = yield listUpdate(action.payload.subscriptionId, action.payload.clientId)
          yield put(actions.requestUserPaymentsSuccess(data.data, data.summary))
        } catch (error) {
          yield put(actions.requestUserPaymentsFail())
        }
      }
  )

  yield takeLatest(GET_PAYMENT_TO_UPDATE, function* getUpdatePaymentSaga() {
    try {
      const paymentId: string = yield select(selectors.getPaymentToUpdateId)
      const {data} = yield getPaymentById(paymentId)
      yield put(actions.requestPaymentToUpdateData(data.data))
    } catch (error) {
      yield put(actions.requestPaymentToUpdateDataFail())
    }
  })

  yield takeLatest(PUT_PAYMENT_UPDATE_REQUEST, function* updatePaymentSaga() {
    const payment: IUserPayment = yield select(selectors.getPaymentDataToUpdate)
    const {res, err} = yield updatePayment(payment)
    if (res) {
      yield put(actions.updatePaymentDataSuccess())
      const {data} = yield listUpdate(payment.subscription_id, payment.client_id)
      yield put(actions.requestUserPaymentsSuccess(data.data, data.summary))
    } else if (err) {
      yield put(actions.requestPaymentToUpdateFail(err))
    }
  })
}
