import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { useIntl } from "react-intl"
import LogsHeader from "./LogsHeader"
import { PageTitle } from '../../../_metronic/layout/core'
import GoogleAds from "./components/GoogleAds"
import MicrosoftAdsRequests from "./components/MicrosoftAdsRequests"
import MicrosoftAdsErrors from "./components/MicrosoftAdsErrors"

const LogsPage: React.FC = () => {
    const intl = useIntl()
    return (
        <div className='card'>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'LOGS'})}</PageTitle>
            <div className='card-body'>
                <LogsHeader />
                <div className='content-area'>
                    <Switch>
                        <Route exact path='/logs/ads' component={GoogleAds} />
                    </Switch>
                    <Switch>
                        <Route exact path='/logs/requests' component={MicrosoftAdsRequests} />
                    </Switch>
                    <Switch>
                        <Route exact path='/logs/errors' component={MicrosoftAdsErrors} />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default LogsPage
