import React from "react"
import { Button, Modal } from "react-bootstrap"
import { useIntl } from "react-intl"

interface DeleteConfirmationModalProps {
    show: boolean;
    onClose: () => void;
    onConfirm: () => void;
    titleId: string;
    bodyId: string;
    entityName?: string;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
                                                                             show,
                                                                             onClose,
                                                                             onConfirm,
                                                                             titleId,
                                                                             bodyId,
                                                                             entityName
                                                                         }) => {
    const intl = useIntl()

    return (
        <Modal show={show} onHide={onClose} backdrop="static" keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: titleId })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {intl.formatMessage({ id: bodyId }, { entityName })}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onClose}>
                    {intl.formatMessage({ id: 'CANCEL' })}
                </Button>
                <Button variant="primary" onClick={onConfirm}>
                    {intl.formatMessage({ id: 'DELETE' })}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteConfirmationModal
