import axios from 'axios'
import {IClient} from '../models/Client'

const API_URL = process.env.REACT_APP_API_URL || 'api'

export const CLIENT_API = `${API_URL}/shopaiUsers`

export async function getClientsList(
    page: number,
    perPage: number,
    filter: string,
    search?: string
) {
  const params: Record<string, string | number> = {
    page: encodeURIComponent(page.toString()),
    'per-page': encodeURIComponent(perPage.toString()),
    sort: encodeURIComponent(filter),
  };

  if (search) {
    params.search = encodeURIComponent(search.trim());
  }

  return await axios.get(CLIENT_API, { params });
}


export async function getClientById(clientId: number) {
  return await axios.get(`${CLIENT_API}/${clientId}`)
}

export async function deleteClient(clientId: number) {
  return await axios.delete(`${CLIENT_API}/${clientId}`)
}

export async function updateClient(updateClient: IClient) {
  return await axios
    .put(`${CLIENT_API}/${updateClient.id}`, updateClient)
    .then((res) => ({res}))
    .catch((err) => ({err}))
}
