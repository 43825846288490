import React, { useEffect, useState, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { actions } from '../redux/microsoftAds/RequestRedux'
import { useIntl } from 'react-intl'
import { Table } from 'react-bootstrap'
import Pagination from '../../common/Pagination/Pagination'
import PageItemsSelect from '../../common/PageItemsSelect/PageItemsSelect'
import { IRequest, SORT_OPTIONS } from '../models/Request'
import { ITEM_PER_PAGE } from '../../common/PageItemsSelect/models/PerPage'

const MicrosoftAdsRequests: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const { requests, total, loading } = useSelector((state: RootState) => ({
        requests: state.microsoftAdsRequests.requests || [],
        total: state.microsoftAdsRequests.total || 0,
        loading: state.microsoftAdsRequests.loading,
    }))

    const [sortOrder, setSortOrder] = useState<string>(SORT_OPTIONS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const totalPages = Math.ceil(total / itemsPerPage)

    useEffect(() => {
        dispatch(actions.requestRequests({ sortOrder, page: currentPage, perPage: itemsPerPage }))
    }, [dispatch, sortOrder, currentPage, itemsPerPage])

    const handleItemsPerPageChange = useCallback((event) => {
        setItemsPerPage(Number(event.target.value))
        setCurrentPage(1)
    }, [])

    const handleSortChange = useCallback((event) => {
        setSortOrder(event.target.value)
        setCurrentPage(1)
    }, [])

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({id: 'MICROSOFT_ADS_NETWORK'})}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={sortOrder}
                            handle={handleSortChange}
                            options={SORT_OPTIONS}
                        />
                    </div>
                </div>
            </div>

            {loading ? (
                <p>{intl.formatMessage({id: 'LOADING'})}...</p>
            ) : requests.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 gs-0 gy-4 mt-4">
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th>{intl.formatMessage({id: 'REQUEST'})}</th>
                        <th>{intl.formatMessage({id: 'RESPONSE'})}</th>
                        <th>{intl.formatMessage({id: 'ADDITIONAL_INFO'})}</th>
                        <th>{intl.formatMessage({id: 'CREATED_AT'})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requests.map((request: IRequest, index: number) => (
                        <tr key={index}>
                            <td style={{
                                maxWidth: '550px'
                            }}>
                                {request.request}
                            </td>
                            <td style={{
                                maxWidth: '350px'
                            }}>
                                {request.response}</td>
                            <td style={{
                                maxWidth: '350px'
                            }}>
                                {request.additional_info}</td>
                            <td>{new Date(request.created_at).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <p>{intl.formatMessage({id: 'NO_DATA'})}</p>
            )}
            <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6">
                    <PageItemsSelect
                        defaultValue={itemsPerPage}
                        handle={handleItemsPerPageChange}
                        options={ITEM_PER_PAGE}
                    />
                </div>
                <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </div>
        </div>
    )
}

export default MicrosoftAdsRequests
