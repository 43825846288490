import React, { useContext, useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import { Form, Tabs, Tab } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { IEmailTemplateLang } from '../models/EmailTemplateLang'
import { AlertContext } from '../../../../_metronic/layout/core/AlertContext'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

interface EmailTemplateFormProps {
    initialCode?: string
    initialTranslations?: IEmailTemplateLang[]
    onSubmit: (code: string, translations: IEmailTemplateLang[]) => void
}

export interface EmailTemplateFormRef {
    submitForm: () => void
}

const defaultInitialTranslations: IEmailTemplateLang[] = [
    { country_code: 'pl', subject: '', body: '', email_template_id: 0 },
    { country_code: 'en', subject: '', body: '', email_template_id: 0 },
]

const EmailTemplateForm = forwardRef<EmailTemplateFormRef, EmailTemplateFormProps>(
    ({ initialCode = '', initialTranslations = defaultInitialTranslations, onSubmit }, ref) => {
        const intl = useIntl()
        const { showAlert } = useContext(AlertContext)
        const [code, setCode] = useState<string>(initialCode)
        const [translations, setTranslations] = useState<IEmailTemplateLang[]>(initialTranslations)
        const [activeTab, setActiveTab] = useState<string>('pl')

        useEffect(() => {
            setCode(initialCode)
            setTranslations(initialTranslations)
        }, [initialCode, initialTranslations])

        useImperativeHandle(ref, () => ({
            submitForm: () => {
                handleFormSubmit()
            },
        }))

        const handleFormSubmit = () => {

            if (!code) {
                showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.CODE.REQ' })]], 'danger', true)
                return
            }

            if (translations.some((t) => !t.subject || !t.body)) {

                const missingTranslations = translations
                    .filter((t) => !t.subject || !t.body)
                    .map((t) => t.country_code);

                if (missingTranslations.length == 2) {
                    showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.TRANSLATIONS.REQ' })]], 'danger', true)
                }
                else if (missingTranslations.includes('pl')) {
                    showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.TRANSLATIONS.REQ.PL' })]], 'danger', true)
                }
                else if (missingTranslations.includes('en')) {
                    showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.TRANSLATIONS.REQ.EN' })]], 'danger', true)
                }

                return
            }

            onSubmit(code, translations)
        }

        const handleTranslationChange = (field: 'subject' | 'body', value: string, countryCode: string) => {
            setTranslations((prevTranslations) =>
                prevTranslations.map((t) =>
                    t.country_code === countryCode ? { ...t, [field]: value } : t
                )
            )
        }

        return (
            <Form>
                <Form.Group className='mb-3'>
                    <Form.Label>{intl.formatMessage({ id: 'EMAIL_TEMPLATE.CODE' })}</Form.Label>
                    <Form.Control
                        type='text'
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        placeholder={intl.formatMessage({ id: 'EMAIL_TEMPLATE.CODE.PLACEHOLDER' })}
                    />
                </Form.Group>
                <Tabs
                    id='translation-tabs'
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key || 'pl')}
                    className='mb-3'
                >
                    {translations.map((translation) => (
                        <Tab
                            key={translation.country_code}
                            eventKey={translation.country_code}
                            title={translation.country_code.toUpperCase()}
                        >
                            <Form.Group className='mb-3'>
                                <Form.Label>{intl.formatMessage({ id: 'EMAIL_TEMPLATE.SUBJECT' })}</Form.Label>
                                <Form.Control
                                    type='text'
                                    value={translation.subject}
                                    onChange={(e) =>
                                        handleTranslationChange('subject', e.target.value, translation.country_code)
                                    }
                                    placeholder={intl.formatMessage({ id: 'EMAIL_TEMPLATE.SUBJECT.PLACEHOLDER' })}
                                />
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <Form.Label>{intl.formatMessage({ id: 'EMAIL_TEMPLATE.BODY' })}</Form.Label>
                                <ReactQuill
                                    value={translation.body}
                                    onChange={(content) =>
                                        handleTranslationChange('body', content, translation.country_code)
                                    }
                                    modules={{
                                        toolbar: [
                                            [{ header: [1, 2, false] }],
                                            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                                            [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
                                            ['link', 'image'],
                                            ['clean'],
                                        ],
                                    }}
                                    formats={[
                                        'header',
                                        'bold',
                                        'italic',
                                        'underline',
                                        'strike',
                                        'blockquote',
                                        'list',
                                        'bullet',
                                        'indent',
                                        'link',
                                        'image',
                                    ]}
                                />
                            </Form.Group>
                        </Tab>
                    ))}
                </Tabs>
            </Form>
        )
    }
)

export default React.memo(EmailTemplateForm)
