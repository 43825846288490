import React from 'react'
import { Table } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { IEmailTemplate } from '../models/EmailTemplate'
import EmailTemplateListItem from './EmailTemplateListItem'

interface EmailTemplateListProps {
    emailTemplates: IEmailTemplate[]
    onEdit: (template: IEmailTemplate) => void
    onDelete: (id: number) => void
}

const EmailTemplateList: React.FC<EmailTemplateListProps> = ({
                                                                 emailTemplates,
                                                                 onEdit,
                                                                 onDelete,
                                                             }) => {
    const intl = useIntl()

    return (
        <div className='card-body py-3'>
            <Table
                bordered
                className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'
            >
                <thead>
                <tr>
                    <th>{intl.formatMessage({id: 'EMAIL_TEMPLATE.CODE'})}</th>
                    <th>{intl.formatMessage({id: 'CREATED_AT'})}</th>
                    <th>{intl.formatMessage({id: 'ACTIONS'})}</th>
                </tr>
                </thead>

                <tbody>
                {emailTemplates && emailTemplates.length > 0 ? (
                    emailTemplates.map((template) => (
                        <EmailTemplateListItem
                            key={template.id}
                            template={template}
                            onEdit={onEdit}
                            onDelete={onDelete}
                        />
                    ))
                ) : (
                    <tr>
                        <td colSpan={4} className='text-center'>
                            {intl.formatMessage({id: 'NO_DATA'})}
                        </td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    )
}

export default EmailTemplateList
