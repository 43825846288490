import { put, takeLatest, select } from 'redux-saga/effects'
import { fetchEmailTemplates } from './EmailTemplateCRUD'
import { IEmailTemplate, EMAIL_TEMPLATE_LIST_FILTERS } from '../models/EmailTemplate'
import { RootState } from '../../../../setup'

export const EMAIL_TEMPLATE_REQUEST = 'EMAIL_TEMPLATE_REQUEST'
export const EMAIL_TEMPLATE_REQUEST_SUCCESS = 'EMAIL_TEMPLATE_REQUEST_SUCCESS'
export const EMAIL_TEMPLATE_REQUEST_FAIL = 'EMAIL_TEMPLATE_REQUEST_FAIL'

export const CHANGE_EMAIL_TEMPLATE_FILTER = 'CHANGE_EMAIL_TEMPLATE_FILTER'

export interface IEmailTemplateState {
    templates: IEmailTemplate[]
    loading: boolean
    error: boolean
    filter: string
}

const initialState: IEmailTemplateState = {
    templates: [],
    loading: false,
    error: false,
    filter: EMAIL_TEMPLATE_LIST_FILTERS[0].value,
}

export const reducer = (
    state: IEmailTemplateState = initialState,
    action: any
): IEmailTemplateState => {
    switch (action.type) {
        case EMAIL_TEMPLATE_REQUEST:
            return { ...state, loading: true, error: false }
        case EMAIL_TEMPLATE_REQUEST_SUCCESS:
            return { ...state, templates: action.payload.templates, loading: false }
        case EMAIL_TEMPLATE_REQUEST_FAIL:
            return { ...state, loading: false, error: true }
        case CHANGE_EMAIL_TEMPLATE_FILTER:
            return { ...state, filter: action.payload }
        default:
            return state
    }
}

export const actions = {
    requestEmailTemplates: () => ({ type: EMAIL_TEMPLATE_REQUEST }),
    requestEmailTemplatesSuccess: (templates: IEmailTemplate[]) => ({
        type: EMAIL_TEMPLATE_REQUEST_SUCCESS,
        payload: { templates },
    }),
    requestEmailTemplatesFail: () => ({ type: EMAIL_TEMPLATE_REQUEST_FAIL }),
    changeEmailTemplateFilter: (newFilter: string) => ({
        type: CHANGE_EMAIL_TEMPLATE_FILTER,
        payload: newFilter,
    }),
}

export const selectors = {
    getEmailTemplates: (state: RootState) => state.emailTemplates.templates,
    getFilter: (state: RootState) => state.emailTemplates.filter,
}

function* fetchEmailTemplatesSaga() {
    try {
        const filter: string = yield select(selectors.getFilter)
        const { data } = yield fetchEmailTemplates(1, 1000, filter)
        yield put(actions.requestEmailTemplatesSuccess(data))
    } catch (error) {
        yield put(actions.requestEmailTemplatesFail())
    }
}

export function* saga() {
    yield takeLatest(EMAIL_TEMPLATE_REQUEST, fetchEmailTemplatesSaga)
}
