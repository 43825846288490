import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { actions } from '../redux/googleAds/GoogleAdsRedux'
import { useIntl } from 'react-intl'
import { Table } from 'react-bootstrap'
import PageItemsSelect from "../../common/PageItemsSelect/PageItemsSelect"
import Pagination from "../../common/Pagination/Pagination"
import { IGoogleAds, SORT_OPTIONS } from '../models/GoogleAds'

const GoogleAds: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const logs = useSelector((state: RootState) => state.googleAdsLogs.logs.logs || [])
    const loading = useSelector((state: RootState) => state.googleAdsLogs.loading)

    const [sortOrder, setSortOrder] = useState<string>(SORT_OPTIONS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const itemsPerPage = 10

    const totalPages = Math.ceil(logs.length / itemsPerPage)
    const pagedLogs = logs.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    useEffect(() => {
        dispatch(actions.requestLogs(sortOrder))
    }, [dispatch, sortOrder])

    const handleSortChange = useCallback((event) => {
        setSortOrder(event.target.value)
        dispatch(actions.requestLogs(event.target.value))
    }, [dispatch])

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({ id: 'GOOGLE_ADS_LOGS' })}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={sortOrder}
                            handle={handleSortChange}
                            options={SORT_OPTIONS}
                        />
                    </div>
                </div>
            </div>

            {loading ? (
                <p>{intl.formatMessage({ id: 'LOADING' })}...</p>
            ) : pagedLogs.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-4">
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th>ID</th>
                        <th>{intl.formatMessage({ id: 'CUSTOMER_ID' })}</th>
                        <th>{intl.formatMessage({ id: 'ACTION' })}</th>
                        <th>{intl.formatMessage({ id: 'STATUS' })}</th>
                        <th>{intl.formatMessage({ id: 'DESCRIPTION' })}</th>
                        <th>{intl.formatMessage({ id: 'CREATED_AT' })}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {pagedLogs.map((log: IGoogleAds) => (
                        <tr key={log.id}>
                            <td>{log.id}</td>
                            <td>{log.customer_id || ''}</td>
                            <td>{log.action || ''}</td>
                            <td>{log.status || ''}</td>
                            <td>{log.description || ''}</td>
                            <td>{new Date(log.created_at || '').toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <p>{intl.formatMessage({ id: 'NO_LOGS_FOUND' })}</p>
            )}

            <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
    )
}

export default GoogleAds
