import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../../setup'
import { actions } from '../redux/microsoftAds/ErrorRedux'
import { useIntl } from 'react-intl'
import { Table } from 'react-bootstrap'
import PageItemsSelect from '../../common/PageItemsSelect/PageItemsSelect'
import Pagination from '../../common/Pagination/Pagination'
import { IError, SORT_OPTIONS } from '../models/Error'
import { ITEM_PER_PAGE } from "../../common/PageItemsSelect/models/PerPage"

const MicrosoftAdsErrors: React.FC = () => {
    const intl = useIntl()
    const dispatch = useDispatch()

    const { errors, total, loading } = useSelector((state: RootState) => ({
        errors: state.microsoftAdsErrors.errors || [],
        total: state.microsoftAdsErrors.total || 0,
        loading: state.microsoftAdsErrors.loading,
    }))

    const [sortOrder, setSortOrder] = useState<string>(SORT_OPTIONS[0].value)
    const [currentPage, setCurrentPage] = useState<number>(1)
    const [itemsPerPage, setItemsPerPage] = useState<number>(10)
    const totalPages = Math.ceil(total / itemsPerPage)

    useEffect(() => {
        dispatch(actions.requestErrors({ sortOrder, page: currentPage, limit: itemsPerPage }))
    }, [dispatch, sortOrder, currentPage, itemsPerPage])

    const handleSortChange = useCallback((event) => {
        setSortOrder(event.target.value)
        setCurrentPage(1)
    }, [])

    const handleItemsPerPageChange = useCallback((event) => {
        setItemsPerPage(Number(event.target.value))
        setCurrentPage(1)
    }, [])

    return (
        <div className="card-body mt-5">
            <div className="row">
                <div className="col-12 d-flex justify-content-between align-items-center">
                    <h3 className="mb-0">{intl.formatMessage({id: 'MICROSOFT_ADS_ERRORS'})}</h3>
                    <div className="d-flex gap-2 align-items-center">
                        <PageItemsSelect
                            defaultValue={sortOrder}
                            handle={handleSortChange}
                            options={SORT_OPTIONS}
                        />
                    </div>
                </div>
            </div>

            {loading ? (
                <p>{intl.formatMessage({id: 'LOADING'})}...</p>
            ) : errors.length > 0 ? (
                <Table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4 mt-4">
                    <thead>
                    <tr className="fw-bolder text-muted">
                        <th>{intl.formatMessage({id: 'MESSAGE'})}</th>
                        <th>{intl.formatMessage({id: 'STACKTRACE'})}</th>
                        <th>{intl.formatMessage({id: 'ADDITIONAL_INFO'})}</th>
                        <th>{intl.formatMessage({id: 'CREATED_AT'})}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {errors.map((error: IError, index: number) => (
                        <tr key={index}>
                            <td>{error.message}</td>
                            <td>{error.stacktrace || '-'}</td>
                            <td>{error.additional_info || '-'}</td>
                            <td>{new Date(error.created_at).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            ) : (
                <p>{intl.formatMessage({id: 'NO_DATA'})}</p>
            )}

            <div className="d-flex flex-stack flex-wrap pt-10">
                <div className="fs-6">
                    <PageItemsSelect
                        defaultValue={itemsPerPage}
                        handle={handleItemsPerPageChange}
                        options={ITEM_PER_PAGE}
                    />
                </div>
                <Pagination pages={totalPages} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
            </div>
        </div>
    )
}

export default MicrosoftAdsErrors
