import {IEmailTemplateLang} from "./EmailTemplateLang"

export interface IEmailTemplate {
    id?: number
    code: string
    translations: IEmailTemplateLang[]
    created_at?: string
}

export const EMAIL_TEMPLATE_LIST_FILTERS = [
    { value: 'sort=-created_at', label: 'CREATED_AT.SORT.DOWN' },
    { value: 'sort=created_at', label: 'CREATED_AT.SORT.UP' },
    { value: 'sort=-translations.subject', label: 'TITLE.SORT.DOWN' },
    { value: 'sort=translations.subject', label: 'TITLE.SORT.UP' },
    { value: 'sort=-code', label: 'CODE.SORT.DOWN' },
    { value: 'sort=code', label: 'CODE.SORT.UP' },
]