import axios from 'axios'
import { IEmailTemplate } from '../models/EmailTemplate'

const API_URL = process.env.REACT_APP_API_URL || 'api'
export const EMAIL_TEMPLATE_API = `${API_URL}/email-templates`

export async function fetchEmailTemplates(page: number, limit: number, filter: string = '') {
    try {
        const res = await axios.get(`${EMAIL_TEMPLATE_API}?page=${page}&limit=${limit}&${filter}`)
        return res.data
    } catch (err) {
        throw new Error(`Error fetching email templates: ${err}`)
    }
}

export async function createEmailTemplate(newTemplate: IEmailTemplate) {
    try {
        const res = await axios.post(`${EMAIL_TEMPLATE_API}`, newTemplate)
        return res.data
    } catch (err: any) {
        throw err.response?.data?.error || 'Error creating email template'
    }
}

export async function updateEmailTemplate(id: number, updatedTemplate: IEmailTemplate) {
    try {
        const res = await axios.put(`${EMAIL_TEMPLATE_API}/${id}`, updatedTemplate)
        return res.data
    } catch (err: any) {
        throw err.response?.data?.error || 'Error updating email template'
    }
}

export async function deleteEmailTemplate(id: number) {
    try {
        await axios.delete(`${EMAIL_TEMPLATE_API}/${id}`)
    } catch (err) {
        throw new Error(`Error deleting email template: ${err}`)
    }
}