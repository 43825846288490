import { IError } from "../../models/Error"
import { ActionWithPayload } from "../../../../../setup"
import { call, put, takeLatest } from "redux-saga/effects"
import { SagaIterator } from "redux-saga"
import { getErrors} from "./ErrorCRUD"

export const ERRORS_REQUEST = 'ERRORS_REQUEST'
export const ERRORS_REQUEST_SUCCESS = 'ERRORS_REQUEST_SUCCESS'
export const ERRORS_REQUEST_FAIL = 'ERRORS_REQUEST_FAIL'

export interface IErrorState {
    errors: IError[]
    loading: boolean
    total: number
}

const initialState: IErrorState = {
    errors: [],
    loading: false,
    total: 0,
}

export const reducer = (
    state: IErrorState = initialState,
    action: ActionWithPayload<any>
): IErrorState => {
    switch (action.type) {
        case ERRORS_REQUEST:
            return { ...state, loading: true }
        case ERRORS_REQUEST_SUCCESS:
            return {
                ...state,
                errors: action.payload.errors || [],
                total: action.payload.total || 0,
                loading: false,
            }
        case ERRORS_REQUEST_FAIL:
            return { ...state, errors: [], total: 0, loading: false }
        default:
            return state
    }
}


export const actions = {
    requestErrors: (params: { sortOrder: string; page: number; limit: number }) => ({
        type: ERRORS_REQUEST,
        payload: params,
    }),
    requestErrorsSuccess: (errors: IError[], total: number) => ({
        type: ERRORS_REQUEST_SUCCESS,
        payload: { errors, total },
    }),
    requestErrorsFail: () => ({ type: ERRORS_REQUEST_FAIL }),
}


function* fetchErrors(action: ActionWithPayload<{ sortOrder: string; page: number; limit: number }>): SagaIterator {
    try {
        const { sortOrder = 'sort=-created_at', page = 1, limit = 10 } = action.payload || {}

        const response = yield call(() =>
            getErrors(`${sortOrder}&page=${page}&limit=${limit}`)
        )
        const { data, meta } = response.data

        yield put(actions.requestErrorsSuccess(data, meta.total))
    } catch (error) {
        yield put(actions.requestErrorsFail())
    }
}


export function* saga(): SagaIterator {
    yield takeLatest(ERRORS_REQUEST, fetchErrors)
}
