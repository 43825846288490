import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { IEmailTemplate } from '../models/EmailTemplate'
import { KTSVG } from '../../../../_metronic/helpers'
import { IEmailTemplateLang } from '../models/EmailTemplateLang'
import DeleteConfirmationEmail from '../../common/Modal/DeleteConfirmationEmail'
import EmailTemplateContentModal from "./EmailTemplateContentModal"

interface EmailTemplateListItemProps {
    template: IEmailTemplate
    onEdit: (template: IEmailTemplate) => void
    onDelete: (id: number) => void
}

const EmailTemplateListItem: React.FC<EmailTemplateListItemProps> = ({
                                                                         template,
                                                                         onEdit,
                                                                         onDelete,
                                                                     }) => {
    const intl = useIntl()
    const [showDeleteModal, setShowDeleteModal] = useState(false)
    const [showContentModal, setShowContentModal] = useState(false)

    const renderSubjects = () => {
        return template.translations.map((translation: IEmailTemplateLang) => (
            <div key={translation.country_code}>
                <strong>{translation.country_code.toUpperCase()}:</strong>{' '}
                {translation.subject}
            </div>
        ))
    }

    const renderTranslations = () => {
        return template.translations.map((translation: IEmailTemplateLang) => (
            <div key={translation.country_code} className='mb-2'>
                <strong>{translation.country_code.toUpperCase()}:</strong>
                <div>
                    <strong>{intl.formatMessage({ id: 'EMAIL_TEMPLATE.SUBJECT' })}:</strong>{' '}
                    {translation.subject}
                </div>
                <div>
                    <strong>{intl.formatMessage({ id: 'EMAIL_TEMPLATE.BODY' })}:</strong>
                    <div dangerouslySetInnerHTML={{ __html: translation.body }} />
                </div>
            </div>
        ))
    }

    const handleDelete = () => {
        onDelete(template.id!)
        setShowDeleteModal(false)
    }

    const handleShowContent = (showModal: boolean) => {
        setShowContentModal(showModal)
    }

    return (
        <>
            <tr>
                <td>{template.code}</td>
                <td>{new Date(template.created_at || '').toLocaleString()}</td>
                <td>
                    <div className="d-flex flex-wrap flex-lg-nowrap gap-1">
                        <Button
                            variant='primary'
                            className='btn btn-icon btn-bg-light btn-active-color-warning btn-sm ms-1 my-1'
                            onClick={() => handleShowContent(true)}
                        >
                            <KTSVG
                                path='/media/icons/duotone/Communication/Mail.svg'
                                className='svg-icon-3'
                            />
                        </Button>
                        <Button
                            variant='light'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm ms-1 my-1'
                            onClick={() => onEdit(template)}
                        >
                            <KTSVG
                                path='/media/icons/duotone/Communication/Write.svg'
                                className='svg-icon-3'
                            />
                        </Button>
                        <Button
                            variant='light'
                            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm ms-1 my-1'
                            onClick={() => setShowDeleteModal(true)}
                        >
                            <KTSVG
                                path='/media/icons/duotone/General/Trash.svg'
                                className='svg-icon-3'
                            />
                        </Button>
                    </div>
                </td>
            </tr>

            <DeleteConfirmationEmail
                show={showDeleteModal}
                onClose={() => setShowDeleteModal(false)}
                onConfirm={handleDelete}
                titleId='EMAIL_TEMPLATE.DELETE_TITLE'
                bodyId='EMAIL_TEMPLATE.DELETE_BODY'
                entityName={template.code}
            />
            <EmailTemplateContentModal
                show={showContentModal}
                handleClose={() => handleShowContent(false)}
                template={template}
            />

        </>
    );
};

export default EmailTemplateListItem
