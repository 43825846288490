import React, { useEffect, useState } from 'react'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import { IEmailTemplate } from '../models/EmailTemplate'
import { IEmailTemplateLang } from '../models/EmailTemplateLang'

interface EmailTemplateContentModalProps {
    show: boolean
    handleClose: () => void
    template: IEmailTemplate
}

const EmailTemplateContentModal: React.FC<EmailTemplateContentModalProps> = ({
                                                                                 show,
                                                                                 handleClose,
                                                                                 template,
                                                                             }) => {
    const intl = useIntl()
    const [translations, setTranslations] = useState<IEmailTemplateLang[]>([])
    const [code, setCode] = useState<string>('')
    const [activeTab, setActiveTab] = useState<string>('pl')

    useEffect(() => {
        setCode(template.code)
        setTranslations(template.translations)
    }, [template])

    return (
        <Modal show={show} onHide={handleClose} size="xl">
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'EMAIL_TEMPLATE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    id="translation-tabs"
                    activeKey={activeTab}
                    onSelect={(key) => setActiveTab(key || 'pl')}
                    className="mb-3"
                >
                    {translations.map((translation) => (
                        <Tab
                            key={translation.country_code}
                            eventKey={translation.country_code}
                            title={translation.country_code.toUpperCase()}
                        >
                            <div className="mb-3 mt-4">
                                <h4>
                                    {translation.subject ||
                                        intl.formatMessage({ id: 'EMAIL_TEMPLATE.NO_SUBJECT' })}
                                </h4>
                            </div>
                            <div className="mb-3">
                                <div
                                    className="email-template-body"
                                    style={{ overflowX: 'auto', wordWrap: 'break-word' }}
                                >
                                    <div
                                        className="d-block w-100"
                                        dangerouslySetInnerHTML={{
                                            __html: translation.body || '',
                                        }}
                                    />
                                </div>
                            </div>
                        </Tab>
                    ))}
                </Tabs>
            </Modal.Body>
            <Modal.Footer>
                <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleClose}
                >
                    {intl.formatMessage({ id: 'CLOSE' })}
                </button>
            </Modal.Footer>
        </Modal>
    )
}

export default EmailTemplateContentModal
