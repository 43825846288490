import React, { useRef } from 'react'
import { Modal, Button } from 'react-bootstrap'
import { useIntl } from 'react-intl'
import EmailTemplateForm, { EmailTemplateFormRef } from './EmailTemplateForm'
import { createEmailTemplate } from '../redux/EmailTemplateCRUD'
import { IEmailTemplateLang } from '../models/EmailTemplateLang'
import { AlertContext } from '../../../../_metronic/layout/core/AlertContext'

interface AddEmailTemplateModalProps {
    show: boolean
    handleClose: () => void
    loadEmailTemplates: () => void
}

const AddEmailTemplateModal: React.FC<AddEmailTemplateModalProps> = ({ show, handleClose, loadEmailTemplates }) => {
    const intl = useIntl()
    const { showAlert } = React.useContext(AlertContext)
    const formRef = useRef<EmailTemplateFormRef>(null)

    const handleAddTemplate = async (code: string, translations: IEmailTemplateLang[]) => {
        const newTemplate = { code, translations }
        try {
            await createEmailTemplate(newTemplate)
            showAlert([[intl.formatMessage({ id: 'EMAIL_TEMPLATE.ADD.SUCCESS' })]], 'success', true, 'SUCCESS')
            handleClose()
            loadEmailTemplates()
        } catch (error: any) {
            showAlert([[intl.formatMessage({ id: error ?? 'EMAIL_TEMPLATE.ADD.ERROR' })]], 'danger', true)
        }
    }

    const handleSave = () => {
        formRef.current?.submitForm()
    }

    return (
        <Modal show={show} onHide={handleClose} size='lg'>
            <Modal.Header closeButton>
                <Modal.Title>{intl.formatMessage({ id: 'ADD_EMAIL_TEMPLATE' })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <EmailTemplateForm ref={formRef} onSubmit={handleAddTemplate} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                    {intl.formatMessage({ id: 'CLOSE' })}
                </Button>
                <Button variant='primary' onClick={handleSave}>
                    {intl.formatMessage({ id: 'ADD' })}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default AddEmailTemplateModal
