import React from 'react'
import {PageTitle} from "../../../_metronic/layout/core";
import {useIntl} from 'react-intl'
import EmailTemplates from "../../modules/emailTemplates/components/EmailTemplates";

const EmailTemplateWrapper: React.FC = () => {
    const intl = useIntl()

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'EMAIL_TEMPLATES'})}</PageTitle>
            <EmailTemplates/>
        </>
    )
}

export default EmailTemplateWrapper